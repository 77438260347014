// import { Redirect, Route } from 'react-router-dom';
import { Route } from 'react-router-dom';
import { IonApp, IonRouterOutlet, setupIonicReact } from '@ionic/react';
import { IonReactHashRouter } from '@ionic/react-router';
import Home from './pages/Home';
import Welcome from './pages/Welcome';
import Register from './pages/Register';
import Login from './pages/Login';
import ForgotPassword from './pages/ForgotPassword';
import ResetCode from './pages/ForgotPassword/ResetCode';
import Dashboard from './pages/Dashboard';
import AddJob from './pages/AddJob';
import PerfromInspection from './pages/PerfromInspection';
import JobDocuments from './pages/JobDocuments';
import MaintenanceRequest from './pages/MaintenanceRequest';
import ServiceRequest from './pages/ServiceRequest';
import ContactServiceProvider from './pages/ContactServiceProvider';
import Viewjobs from './pages/AddJob/VewjJobs';
import './App.css';


/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
// import PrivateRoute from './PrivateRoute';
// import { useAuth } from './auth-provider';

setupIonicReact();

const App: React.FC = () => {

  // const [logged] = useAuth();

  return (
    < IonApp >
      <IonReactHashRouter>
        <IonRouterOutlet>
          <Route exact path="/dashboard/">
            <Dashboard />
          </Route>
          <Route exact path="/addJob">
            <AddJob />
          </Route>
          <Route exact path="/viewjobs" 
            render={(props) => {
              // return logged ? <Viewjobs/> : <Redirect to="/login" />;
              return <Viewjobs/>;
            }}>
          </Route>
          {/* <PrivateRoute exact path="/viewjobs" component={Viewjobs} /> */}
          <Route exact path="/perfromInspection">
            <PerfromInspection />
          </Route>
          <Route exact path="/jobDocuments">
            <JobDocuments />
          </Route>
          <Route exact path="/maintenanceRequest">
            <MaintenanceRequest />
          </Route>
          <Route exact path="/serviceRequest">
            <ServiceRequest />
          </Route>
          <Route exact path="/contactServiceProvider">
            <ContactServiceProvider />
          </Route>
          <Route exact path="/home">
            <Home />
          </Route>
          <Route exact path="/register">
            <Register />
          </Route>
          <Route exact path="/login">
            <Login />
          </Route>
          <Route exact path="/forgotPassword">
            <ForgotPassword />
          </Route>
          <Route exact path="/resetCode">
            <ResetCode />
          </Route>
          <Route exact path="/">
            <Welcome />
          </Route>
        </IonRouterOutlet>
      </IonReactHashRouter>
    </ IonApp>
  );
};

export default App;
