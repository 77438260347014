import {
    IonAlert,
    IonBreadcrumb,
    IonBreadcrumbs,
    IonCard,
    IonCardContent,
    IonCheckbox,
    IonCol,
    IonGrid,
    IonHeader,
    IonIcon,
    IonInput,
    IonLabel,
    IonRow,
    IonSelect,
    IonSelectOption,
    IonToast,
    IonToolbar,
    useIonLoading,
    useIonViewDidEnter
} from '@ionic/react';
import { arrowBack } from "ionicons/icons";
import {
    IonContent,
    IonPage,
    IonButton,
    IonButtons
} from '@ionic/react';
import './index.css';
import { Link, useHistory } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import { API_URL } from '../../config';
import { authFetch } from '../../auth-provider';
import states from '../../config/states';
import areaUnits from '../../config/area-units';
import { logout } from '../../auth-provider';
import * as utils from '../../services/utils';
import Inspector from './Inspector';

const AddJob: React.FC = () => {
    // useIonViewDidEnter(() => {
    //     console.log('ionViewDidEnter event fired');

    //     const searchData = {
    //         type: 'job_belongsto_inspector_relationship',
    //         method: 'add'
    //     };

    //     authFetch(API_URL + "/jobs/relation?" + utils.objectToQueryString(searchData), {
    //         method: 'GET',
    //         headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' }
    //     })
    //         .then(r => {
    //             if(r.ok === false && r.status === 401) {
    //                 logout();
    //                 history.push('/login');
    //                 return;
    //             }
    //             return r.json()
    //         })
    //         .then(response => {
    //             const message = response?.message;
    //             const results = response?.results;
    //             if(!results) {
    //                 setIserror(true);
    //                 setMessage(message);
    //                 console.log('response', response);
    //             }

    //             console.log('results', results);
    //             setInspectors(results);
    //         }).catch(err => {
    //             console.log(err)
    //         });
    // })


    const history = useHistory();

    const [showAlert, setShowAlert] = useState(false);
    // eslint-disable-next-line
    // const [inspectors, setInspectors] = useState([]);

    const [data, setData] = useState<any>();
    const [formData, setFormData] = useState<any>(new FormData());

    const [iserror, setIserror] = useState<boolean>(false);
    const [message, setMessage] = useState<string>("");
    const [isToastOpen, setIsToastOpen] = useState(false);
    const [toastMessage, setToastMessage] = useState<string>("");
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [present, dismiss] = useIonLoading();

    const onFormChange = (key:string, value: any) => {
        setData(() => {
            // console.log('[onFormChange]');
            // console.log('key', key);
            // console.log('value', value);
            // console.log('data', data);

            let newData = {
                ...data,
                ...{
                    [key]: value
                }
            };
    
            if(newData.maintenance_provider_same_as_above === true) {
                newData = {
                    ...newData,
                    ...{
                        maintenance_provider_name: newData?.installation_provider_name,
                        maintenance_provider_location: newData?.installation_provider_location,
                        maintenance_provider_city: newData?.installation_provider_city,
                        maintenance_provider_state: newData?.installation_provider_state,
                        maintenance_provider_zip: newData?.installation_provider_zip,
                        maintenance_provider_phone: newData?.installation_provider_phone,
                        maintenance_provider_email: newData?.installation_provider_email,
                    }
                }
            }

            console.log('newData', newData);
            return newData;
        })

        setFormData(() => {
            // console.log('[onFormChange]');
            // console.log('key', key);
            // console.log('value', value);
            // console.log('data', data);

            let newData = {
                ...data,
                ...{
                    [key]: value
                }
            };
    
            if(newData.maintenance_provider_same_as_above === true) {
                newData = {
                    ...newData,
                    ...{
                        maintenance_provider_name: newData?.installation_provider_name,
                        maintenance_provider_location: newData?.installation_provider_location,
                        maintenance_provider_city: newData?.installation_provider_city,
                        maintenance_provider_state: newData?.installation_provider_state,
                        maintenance_provider_zip: newData?.installation_provider_zip,
                        maintenance_provider_phone: newData?.installation_provider_phone,
                        maintenance_provider_email: newData?.installation_provider_email,
                    }
                }
            }
            return utils.jsonToFormData(newData, formData)
        });
    };

    const onFileChange = (key:string, e: any) => {
        // // Get a reference to the file that has just been added to the input
        // const photo = e.target.files[0];
        // // Create a form data object using the FormData API
        // let data = new FormData();
        // // Add the file that was just added to the form data
        // data.append('photo', photo, photo.name);
        // // POST data to server using Fetch API
        const updatedData = {
            ...data,
            ...{
                [key]: e.target.files
            }
        };
        setData(updatedData);

        setFormData(() => {
            return utils.jsonToFormData(updatedData, formData)
        });
    };

    const onSubmit = (e: any) => {
        e.preventDefault();

        setIserror(false);
        setMessage("");
        setIsToastOpen(false);
        setToastMessage("");

        present({
            message: 'Submitting...',
        });

        authFetch(API_URL + "/jobs", {
            method: 'POST',
            body: formData,
        })
            .then(r => {
                if(r.ok === false && r.status === 401) {
                    logout();
                    history.push('/login');
                    return;
                }
                return r.json()
            })
            .then(response => {
                dismiss();
                const message = response?.message;
                const data = response?.data;
                if(data) {
                    setIsToastOpen(true);
                    setToastMessage("Jobs added successfully.");
                    history.push("/dashboard");
                    return;
                }
                setIserror(true);
                setMessage(message);
                console.log('response', response);
            }).catch(err => {
                dismiss();
                console.log(err)
            });
    };

    const onInspectorChange = useCallback((id: any, inspectorData: any) => {
        setData(() => {
            console.log('id', id);
            console.log('data', data);

            let newData = {
                ...data,
                ...{
                    [id]: inspectorData
                }
            };
    
            if(newData?.maintenance_provider_same_as_above === true) {
                newData = {
                    ...newData,
                    ...{
                        maintenance_provider_name: newData?.installation_provider_name,
                        maintenance_provider_location: newData?.installation_provider_location,
                        maintenance_provider_city: newData?.installation_provider_city,
                        maintenance_provider_state: newData?.installation_provider_state,
                        maintenance_provider_zip: newData?.installation_provider_zip,
                        maintenance_provider_phone: newData?.installation_provider_phone,
                        maintenance_provider_email: newData?.installation_provider_email,
                    }
                }
            }
            console.log('newData', newData);
            return newData;
        })

        setFormData(() => {
            console.log('id', id);
            console.log('data', data);

            let newData = {
                ...data,
                ...{
                    [id]: inspectorData
                }
            };
    
            if(newData?.maintenance_provider_same_as_above === true) {
                newData = {
                    ...newData,
                    ...{
                        maintenance_provider_name: newData?.installation_provider_name,
                        maintenance_provider_location: newData?.installation_provider_location,
                        maintenance_provider_city: newData?.installation_provider_city,
                        maintenance_provider_state: newData?.installation_provider_state,
                        maintenance_provider_zip: newData?.installation_provider_zip,
                        maintenance_provider_phone: newData?.installation_provider_phone,
                        maintenance_provider_email: newData?.installation_provider_email,
                    }
                }
            }
            console.log('newData', newData);
            return utils.jsonToFormData(newData, formData)
        });
    }, [data]);

    return (

        <IonPage>
            <IonContent>

                <IonHeader className="home header-toolbar">
                    <IonToolbar>
                        <IonButtons slot="start">

                            <IonButton color="light" onClick={(e) => {
                                e.preventDefault();
                                history.push('/dashboard');
                            }}>
                                <IonIcon icon={arrowBack} ></IonIcon>
                            </IonButton>

                        </IonButtons>

                        <IonButtons className="ion-justify-content-center">
                            <IonButton color="light">
                                <h1 className="m-0 mr-5 ion-justify-content-center head-class" color="light" >ADD JOB</h1>
                            </IonButton>
                        </IonButtons>

                    </IonToolbar>
                </IonHeader>
                <IonContent>
                    <div>
                        <IonAlert
                            isOpen={iserror}
                            onDidDismiss={() => setIserror(false)}
                            cssClass="my-custom-class"
                            header={"Error!"}
                            message={message}
                            buttons={["Dismiss"]}
                        />
                    </div>

                    <div className="topHeader"></div>

                    <IonGrid style={{ marginBottom: "55px", marginTop: "40px " }}>
                        <IonRow className="profileActionContainer">
                            <IonCol size="12" className="">
                                <IonCard className="profileHeader">

                                    <IonBreadcrumbs style={{ marginTop: "10px " }}>
                                        <Link to="dashboard"><IonBreadcrumb color="medium">Dashboard</IonBreadcrumb></Link>
                                        <IonBreadcrumb color="primary" active={true}>Add Job</IonBreadcrumb>
                                    </IonBreadcrumbs>

                                    <IonCardContent>
                                        {/* <button type="button" className="btn btn-light w-100 mb-3 icon" onClick={(e) => {
                                            e.preventDefault();
                                            history.push('/viewjobs');
                                        }}>View Jobs</button> */}
                                        <form onSubmit={onSubmit}>
                                            <div className="form-row">
                                                <div className="form-group col-md-6">
                                                    <label className="mb-2">Project Name</label>
                                                    <IonInput
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Enter Project Name"
                                                        value={data?.project_name}
                                                        onIonChange={(e) => onFormChange('project_name', e.detail.value)}
                                                    ></IonInput>
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <label className="mb-2">Project Type</label>
                                                    <IonInput
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Enter Project Type"
                                                        value={data?.project_type}
                                                        onIonChange={(e) => onFormChange('project_type', e.detail.value)}
                                                    ></IonInput>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="inputAddress">Street Address</label>
                                                <IonInput
                                                    type="text"
                                                    className="form-control"
                                                    id="inputAddress"
                                                    placeholder="e.g. 1234 Main St..."
                                                    value={data?.location}
                                                    onIonChange={(e) => onFormChange('location', e.detail.value)}
                                                ></IonInput>
                                            </div>

                                            <div className="form-row">
                                                <div className="form-group col-md-6">
                                                    <label >City</label>
                                                    <IonInput
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Enter City Name"
                                                        value={data?.city}
                                                        onIonChange={(e) => onFormChange('city', e.detail.value)}
                                                    ></IonInput>
                                                </div>
                                                <div className="form-group col-md-4">
                                                    <label >State</label>
                                                    <IonSelect
                                                        className="form-control"
                                                        placeholder="Select state"
                                                        value={data?.state}
                                                        onIonChange={(e) => onFormChange('state', e.detail.value)}
                                                    >
                                                        <IonSelectOption key={null} value={null}>Choose...</IonSelectOption>
                                                        {states && states.map((state: any) => {
                                                            return (
                                                                <IonSelectOption key={state.code} value={state.code}>{state.name}</IonSelectOption>
                                                            )
                                                        })}
                                                    </IonSelect>
                                                </div>
                                                <div className="form-group col-md-2">
                                                    <label htmlFor="inputZip">Zip</label>
                                                    <IonInput
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="e.g. 5624637"
                                                        value={data?.zip}
                                                        onIonChange={(e) => onFormChange('zip', e.detail.value)}
                                                    ></IonInput>
                                                </div>
                                            </div>
                                            <div className="form-row">
                                                <div className="form-group col-md-6">
                                                    <label >Parcel Number</label>
                                                    <IonInput
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Enter Parcel Number"
                                                        value={data?.parcel_number}
                                                        onIonChange={(e) => onFormChange('parcel_number', e.detail.value)}
                                                    ></IonInput>
                                                </div>

                                                <div className="form-group col-md-6">
                                                    <label >Total Site Area</label>
                                                    <div className="input-group">
                                                        <IonInput
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="e.g. 1.2.."
                                                            aria-label="Text input with dropdown button"
                                                            value={data?.total_site_area}
                                                            onIonChange={(e) => onFormChange('total_site_area', e.detail.value)}
                                                        ></IonInput>
                                                        <div className="input-group-append">
                                                            <IonSelect
                                                                className=" btn btn-outline-primary form-control"
                                                                value={data?.total_site_area_unit}
                                                                onIonChange={(e) => onFormChange('total_site_area_unit', e.detail.value)}
                                                            >
                                                                {areaUnits && areaUnits.map((areaUnit: any) => {
                                                                    return (
                                                                        <IonSelectOption key={areaUnit.code} value={areaUnit.code}>{areaUnit.name}</IonSelectOption>
                                                                    )
                                                                })}
                                                            </IonSelect>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-row">
                                                <div className="form-group col-md-6">
                                                    <label >Disturbed Area</label>
                                                    <div className="input-group">
                                                        <IonInput
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="e.g. 2.1.."
                                                            aria-label="Text input with dropdown button"
                                                            value={data?.disturbed_area}
                                                            onIonChange={(e) => onFormChange('disturbed_area', e.detail.value)}
                                                        ></IonInput>
                                                        <div className="input-group-append">
                                                            <IonSelect
                                                                className=" btn btn-outline-primary form-control"
                                                                value={data?.disturbed_area_unit}
                                                                onIonChange={(e) => onFormChange('disturbed_area_unit', e.detail.value)}
                                                            >
                                                                {areaUnits && areaUnits.map((areaUnit: any) => {
                                                                    return (
                                                                        <IonSelectOption key={areaUnit.code} value={areaUnit.code}>{areaUnit.name}</IonSelectOption>
                                                                    )
                                                                })}
                                                            </IonSelect>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <label >Permit Number</label>
                                                    <IonInput
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Enter Permit Number"
                                                        value={data?.permit_number}
                                                        onIonChange={(e) => onFormChange('permit_number', e.detail.value)}
                                                    ></IonInput>
                                                </div>
                                            </div>

                                            <div className="head">
                                                <h1 className="h1">Permit Authority</h1>
                                            </div>
                                            <div className="form-group">
                                                <label >Contact Name</label>
                                                <IonInput type="text" className="form-control" placeholder="Enter Contact Name"
                                                        value={data?.permitter_name}
                                                        onIonChange={(e) => onFormChange('permitter_name', e.detail.value)}></IonInput>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="inputAddress">Address</label>
                                                <IonInput type="text" className="form-control" placeholder="e.g. 1234 Main St..."
                                                        value={data?.permitter_location}
                                                        onIonChange={(e) => onFormChange('permitter_location', e.detail.value)}></IonInput>
                                            </div>
                                            <div className="form-row">
                                                <div className="form-group col-md-6">
                                                    <label >City</label>
                                                    <IonInput type="text" className="form-control" placeholder="Enter City Name"
                                                        value={data?.permitter_city}
                                                        onIonChange={(e) => onFormChange('permitter_city', e.detail.value)}></IonInput>
                                                </div>
                                                <div className="form-group col-md-4">
                                                    <label >State</label>
                                                    <IonSelect className="form-control" placeholder="Select state"
                                                        value={data?.permitter_state}
                                                        onIonChange={(e) => onFormChange('permitter_state', e.detail.value)}>
                                                        <IonSelectOption key={null} value={null}>Choose...</IonSelectOption>
                                                        {states && states.map((state: any) => {
                                                            return (
                                                                <IonSelectOption key={state.code} value={state.code}>{state.name}</IonSelectOption>
                                                            )
                                                        })}
                                                    </IonSelect>
                                                </div>
                                                <div className="form-group col-md-2">
                                                    <label htmlFor="inputZip">Zip</label>
                                                    <IonInput type="text" className="form-control" placeholder="e.g. 5624637"
                                                        value={data?.permitter_zip}
                                                        onIonChange={(e) => onFormChange('permitter_zip', e.detail.value)}></IonInput>
                                                </div>
                                            </div>
                                            <div className="form-row">
                                                <div className="form-group col-md-6">
                                                    <label >Phone</label>
                                                    <IonInput type="text" className="form-control" placeholder="Enter phone Number.."
                                                        value={data?.permitter_phone}
                                                        onIonChange={(e) => onFormChange('permitter_phone', e.detail.value)}></IonInput>
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <label >Email</label>
                                                    <IonInput type="email" className="form-control" placeholder="Add Contact Email"
                                                        value={data?.permitter_email}
                                                        onIonChange={(e) => onFormChange('permitter_email', e.detail.value)}></IonInput>
                                                </div>
                                            </div>




                                            <div className="head">
                                                <h1 className="h1">Permittee</h1>
                                            </div>
                                            <div className="form-group">
                                                <label >Contact Name</label>
                                                <IonInput type="text" className="form-control" placeholder="Enter Contact Name"
                                                        value={data?.permittee_name}
                                                        onIonChange={(e) => onFormChange('permittee_name', e.detail.value)}></IonInput>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="inputAddress">Address</label>
                                                <IonInput type="text" className="form-control" placeholder="e.g. 1234 Main St..."
                                                        value={data?.permittee_location}
                                                        onIonChange={(e) => onFormChange('permittee_location', e.detail.value)}></IonInput>
                                            </div>
                                            <div className="form-row">
                                                <div className="form-group col-md-6">
                                                    <label >City</label>
                                                    <IonInput type="text" className="form-control" placeholder="Enter City Name"
                                                        value={data?.permittee_city}
                                                        onIonChange={(e) => onFormChange('permittee_city', e.detail.value)}></IonInput>
                                                </div>
                                                <div className="form-group col-md-4">
                                                    <label >State</label>
                                                    <IonSelect className="form-control" placeholder="Select state"
                                                        value={data?.permittee_state}
                                                        onIonChange={(e) => onFormChange('permittee_state', e.detail.value)}>
                                                        <IonSelectOption key={null} value={null}>Choose...</IonSelectOption>
                                                        {states && states.map((state: any) => {
                                                            return (
                                                                <IonSelectOption key={state.code} value={state.code}>{state.name}</IonSelectOption>
                                                            )
                                                        })}
                                                    </IonSelect>
                                                </div>
                                                <div className="form-group col-md-2">
                                                    <label htmlFor="inputZip">Zip</label>
                                                    <IonInput type="text" className="form-control" placeholder="e.g. 5624637"
                                                        value={data?.permittee_zip}
                                                        onIonChange={(e) => onFormChange('permittee_zip', e.detail.value)}></IonInput>
                                                </div>
                                            </div>
                                            <div className="form-row">
                                                <div className="form-group col-md-6">
                                                    <label >Phone</label>
                                                    <IonInput type="text" className="form-control" placeholder="Enter phone Number.."
                                                        value={data?.permittee_phone}
                                                        onIonChange={(e) => onFormChange('permittee_phone', e.detail.value)}></IonInput>
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <label >Email</label>
                                                    <IonInput type="email" className="form-control" placeholder="Add Contact Email"
                                                        value={data?.permittee_email}
                                                        onIonChange={(e) => onFormChange('permittee_email', e.detail.value)}></IonInput>
                                                </div>
                                            </div>



                                            <div className="head">
                                                <h1 className="h1">Identified / Authorized Inspector</h1>
                                            </div>
                                            <Inspector
                                                inspectorId={"inspector0"}
                                                inspectorTitle="Inspector 1"
                                                onInspectorChange={onInspectorChange}
                                            />
                                            <Inspector
                                                inspectorId={"inspector1"}
                                                inspectorTitle={"Inspector 2"}
                                                onInspectorChange={onInspectorChange}
                                            />
                                            <Inspector
                                                inspectorId={"inspector2"}
                                                inspectorTitle={"Inspector 3"}
                                                onInspectorChange={onInspectorChange}
                                            />
                                            {/* <div className="form-group"> */}
                                                {/* <label >Contact Name</label> */}
                                                {/* <IonSelect className="form-control" placeholder="Select inspector"
                                                    value={data?.inspector_id}
                                                    onIonChange={(e) => onFormChange('inspector_id', e.detail.value)}>
                                                    <IonSelectOption key={null} value={null}>Choose...</IonSelectOption>
                                                    {inspectors && inspectors.map((inspector: any) => {
                                                        return (
                                                            <IonSelectOption key={inspector.id} value={inspector.id}>{inspector.text}</IonSelectOption>
                                                        )
                                                    })}
                                                </IonSelect> */}
                                            {/* </div> */}
                                            {/* <div className="form-group">
                                                <label >Contact Name</label>
                                                <IonInput type="text" className="form-control" placeholder="Enter Contact Name"
                                                        value={data?.inspector_name}
                                                        onIonChange={(e) => onFormChange('inspector_name', e.detail.value)}></IonInput>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="inputAddress">Address</label>
                                                <IonInput type="text" className="form-control" placeholder="e.g. 1234 Main St..."
                                                        value={data?.inspector_location}
                                                        onIonChange={(e) => onFormChange('inspector_location', e.detail.value)}></IonInput>
                                            </div>
                                            <div className="form-row">
                                                <div className="form-group col-md-6">
                                                    <label >City</label>
                                                    <IonInput type="text" className="form-control" placeholder="Enter City Name"
                                                        value={data?.inspector_city}
                                                        onIonChange={(e) => onFormChange('inspector_city', e.detail.value)}></IonInput>
                                                </div>
                                                <div className="form-group col-md-4">
                                                    <label >State</label>
                                                    <IonSelect className="form-control" placeholder="Select state"
                                                        value={data?.inspector_state}
                                                        onIonChange={(e) => onFormChange('inspector_state', e.detail.value)}>
                                                        <IonSelectOption key={null} value={null}>Choose...</IonSelectOption>
                                                        {states && states.map((state: any) => {
                                                            return (
                                                                <IonSelectOption key={state.code} value={state.code}>{state.name}</IonSelectOption>
                                                            )
                                                        })}
                                                    </IonSelect>
                                                </div>
                                                <div className="form-group col-md-2">
                                                    <label htmlFor="inputZip">Zip</label>
                                                    <IonInput type="text" className="form-control" placeholder="e.g. 5624637"
                                                        value={data?.inspector_zip}
                                                        onIonChange={(e) => onFormChange('inspector_zip', e.detail.value)}></IonInput>
                                                </div>
                                            </div>
                                            <div className="form-row">
                                                <div className="form-group col-md-6">
                                                    <label >Phone</label>
                                                    <IonInput type="text" className="form-control" placeholder="Enter phone Number.."
                                                        value={data?.inspector_phone}
                                                        onIonChange={(e) => onFormChange('inspector_phone', e.detail.value)}></IonInput>
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <label >Email</label>
                                                    <IonInput type="email" className="form-control" placeholder="Add Contact Email"
                                                        value={data?.inspector_email}
                                                        onIonChange={(e) => onFormChange('inspector_email', e.detail.value)}></IonInput>
                                                </div>
                                            </div> */}



                                            <div className="head">
                                                <h1 className="h1">Owner</h1>
                                            </div>
                                            <div className="form-group">
                                                <label >Contact Name</label>
                                                <IonInput type="text" className="form-control" placeholder="Enter Contact Name"
                                                        value={data?.owner_name}
                                                        onIonChange={(e) => onFormChange('owner_name', e.detail.value)}></IonInput>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="inputAddress">Address</label>
                                                <IonInput type="text" className="form-control" placeholder="e.g. 1234 Main St..."
                                                        value={data?.owner_location}
                                                        onIonChange={(e) => onFormChange('owner_location', e.detail.value)}></IonInput>
                                            </div>
                                            <div className="form-row">
                                                <div className="form-group col-md-6">
                                                    <label >City</label>
                                                    <IonInput type="text" className="form-control" placeholder="Enter City Name"
                                                        value={data?.owner_city}
                                                        onIonChange={(e) => onFormChange('owner_city', e.detail.value)}></IonInput>
                                                </div>
                                                <div className="form-group col-md-4">
                                                    <label >State</label>
                                                    <IonSelect className="form-control" placeholder="Select state"
                                                        value={data?.owner_state}
                                                        onIonChange={(e) => onFormChange('owner_state', e.detail.value)}>
                                                        <IonSelectOption key={null} value={null}>Choose...</IonSelectOption>
                                                        {states && states.map((state: any) => {
                                                            return (
                                                                <IonSelectOption key={state.code} value={state.code}>{state.name}</IonSelectOption>
                                                            )
                                                        })}
                                                    </IonSelect>
                                                </div>
                                                <div className="form-group col-md-2">
                                                    <label htmlFor="inputZip">Zip</label>
                                                    <IonInput type="text" className="form-control" placeholder="e.g. 5624637"
                                                        value={data?.owner_zip}
                                                        onIonChange={(e) => onFormChange('owner_zip', e.detail.value)}></IonInput>
                                                </div>
                                            </div>
                                            <div className="form-row">
                                                <div className="form-group col-md-6">
                                                    <label >Phone</label>
                                                    <IonInput type="text" className="form-control" placeholder="Enter phone Number.."
                                                        value={data?.owner_phone}
                                                        onIonChange={(e) => onFormChange('owner_phone', e.detail.value)}></IonInput>
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <label >Email</label>
                                                    <IonInput type="email" className="form-control" placeholder="Add Contact Email"
                                                        value={data?.owner_email}
                                                        onIonChange={(e) => onFormChange('owner_email', e.detail.value)}></IonInput>
                                                </div>
                                            </div>



                                            <div className="head">
                                                <h1 className="h1">Contractor</h1>
                                            </div>
                                            <div className="form-group">
                                                <label >Contact Name</label>
                                                <IonInput type="text" className="form-control" placeholder="Enter Contact Name"
                                                        value={data?.contractor_name}
                                                        onIonChange={(e) => onFormChange('contractor_name', e.detail.value)}></IonInput>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="inputAddress">Address</label>
                                                <IonInput type="text" className="form-control" placeholder="e.g. 1234 Main St..."
                                                        value={data?.contractor_location}
                                                        onIonChange={(e) => onFormChange('contractor_location', e.detail.value)}></IonInput>
                                            </div>
                                            <div className="form-row">
                                                <div className="form-group col-md-6">
                                                    <label >City</label>
                                                    <IonInput type="text" className="form-control" placeholder="Enter City Name"
                                                        value={data?.contractor_city}
                                                        onIonChange={(e) => onFormChange('contractor_city', e.detail.value)}></IonInput>
                                                </div>
                                                <div className="form-group col-md-4">
                                                    <label >State</label>
                                                    <IonSelect className="form-control" placeholder="Select state"
                                                        value={data?.contractor_state}
                                                        onIonChange={(e) => onFormChange('contractor_state', e.detail.value)}>
                                                        <IonSelectOption key={null} value={null}>Choose...</IonSelectOption>
                                                        {states && states.map((state: any) => {
                                                            return (
                                                                <IonSelectOption key={state.code} value={state.code}>{state.name}</IonSelectOption>
                                                            )
                                                        })}
                                                    </IonSelect>
                                                </div>
                                                <div className="form-group col-md-2">
                                                    <label htmlFor="inputZip">Zip</label>
                                                    <IonInput type="text" className="form-control" placeholder="e.g. 5624637"
                                                        value={data?.contractor_zip}
                                                        onIonChange={(e) => onFormChange('contractor_zip', e.detail.value)}></IonInput>
                                                </div>
                                            </div>
                                            <div className="form-row">
                                                <div className="form-group col-md-6">
                                                    <label >Phone</label>
                                                    <IonInput type="text" className="form-control" placeholder="Enter phone Number.."
                                                        value={data?.contractor_phone}
                                                        onIonChange={(e) => onFormChange('contractor_phone', e.detail.value)}></IonInput>
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <label >Email</label>
                                                    <IonInput type="email" className="form-control" placeholder="Add Contact Email"
                                                        value={data?.contractor_email}
                                                        onIonChange={(e) => onFormChange('contractor_email', e.detail.value)}></IonInput>
                                                </div>
                                            </div>





                                            <div className="head">
                                                <h1 className="h1">BMP Installation Provider</h1>
                                            </div>
                                            <div className="form-group">
                                                <label >Contact Name</label>
                                                <IonInput type="text" className="form-control" placeholder="Enter Contact Name"
                                                        value={data?.installation_provider_name}
                                                        onIonChange={(e) => onFormChange('installation_provider_name', e.detail.value)}></IonInput>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="inputAddress">Address</label>
                                                <IonInput type="text" className="form-control" placeholder="e.g. 1234 Main St..."
                                                        value={data?.installation_provider_location}
                                                        onIonChange={(e) => onFormChange('installation_provider_location', e.detail.value)}></IonInput>
                                            </div>
                                            <div className="form-row">
                                                <div className="form-group col-md-6">
                                                    <label >City</label>
                                                    <IonInput type="text" className="form-control" placeholder="Enter City Name"
                                                        value={data?.installation_provider_city}
                                                        onIonChange={(e) => onFormChange('installation_provider_city', e.detail.value)}></IonInput>
                                                </div>
                                                <div className="form-group col-md-4">
                                                    <label >State</label>
                                                    <IonSelect className="form-control" placeholder="Select state"
                                                        value={data?.installation_provider_state}
                                                        onIonChange={(e) => onFormChange('installation_provider_state', e.detail.value)}>
                                                        <IonSelectOption key={null} value={null}>Choose...</IonSelectOption>
                                                        {states && states.map((state: any) => {
                                                            return (
                                                                <IonSelectOption key={state.code} value={state.code}>{state.name}</IonSelectOption>
                                                            )
                                                        })}
                                                    </IonSelect>
                                                </div>
                                                <div className="form-group col-md-2">
                                                    <label htmlFor="inputZip">Zip</label>
                                                    <IonInput type="text" className="form-control" placeholder="e.g. 5624637"
                                                        value={data?.installation_provider_zip}
                                                        onIonChange={(e) => onFormChange('installation_provider_zip', e.detail.value)}></IonInput>
                                                </div>
                                            </div>
                                            <div className="form-row">
                                                <div className="form-group col-md-6">
                                                    <label >Phone</label>
                                                    <IonInput type="text" className="form-control" placeholder="Enter phone Number.."
                                                        value={data?.installation_provider_phone}
                                                        onIonChange={(e) => onFormChange('installation_provider_phone', e.detail.value)}></IonInput>
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <label >Email</label>
                                                    <IonInput type="email" className="form-control" placeholder="Add Contact Email"
                                                        value={data?.installation_provider_email}
                                                        onIonChange={(e) => onFormChange('installation_provider_email', e.detail.value)}></IonInput>
                                                </div>
                                            </div>


                                            <div className="head">
                                                <h1 className="h1">Maintenance Provider</h1>
                                            </div>
                                            <div className="custom-control custom-checkbox text-right">
                                                <IonCheckbox
                                                    checked={data?.maintenance_provider_same_as_above}
                                                    onIonChange={(e) => onFormChange('maintenance_provider_same_as_above', e.detail.checked)}
                                                />
                                                <IonLabel> Same as Above</IonLabel>
                                            </div>
                                            <div className="form-group">
                                                <label >Contact Name</label>
                                                <IonInput type="text" className="form-control" placeholder="Enter Contact Name"
                                                        value={data?.maintenance_provider_name}
                                                        onIonChange={(e) => onFormChange('maintenance_provider_name', e.detail.value)}></IonInput>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="inputAddress">Address</label>
                                                <IonInput type="text" className="form-control" placeholder="e.g. 1234 Main St..."
                                                        value={data?.maintenance_provider_location}
                                                        onIonChange={(e) => onFormChange('maintenance_provider_location', e.detail.value)}></IonInput>
                                            </div>
                                            <div className="form-row">
                                                <div className="form-group col-md-6">
                                                    <label >City</label>
                                                    <IonInput type="text" className="form-control" placeholder="Enter City Name"
                                                        value={data?.maintenance_provider_city}
                                                        onIonChange={(e) => onFormChange('maintenance_provider_city', e.detail.value)}></IonInput>
                                                </div>
                                                <div className="form-group col-md-4">
                                                    <label >State</label>
                                                    <IonSelect className="form-control" placeholder="Select state"
                                                        value={data?.maintenance_provider_state}
                                                        onIonChange={(e) => onFormChange('maintenance_provider_state', e.detail.value)}>
                                                        <IonSelectOption key={null} value={null}>Choose...</IonSelectOption>
                                                        {states && states.map((state: any) => {
                                                            return (
                                                                <IonSelectOption key={state.code} value={state.code}>{state.name}</IonSelectOption>
                                                            )
                                                        })}
                                                    </IonSelect>
                                                </div>
                                                <div className="form-group col-md-2">
                                                    <label htmlFor="inputZip">Zip</label>
                                                    <IonInput type="text" className="form-control" placeholder="e.g. 5624637"
                                                        value={data?.maintenance_provider_zip}
                                                        onIonChange={(e) => onFormChange('maintenance_provider_zip', e.detail.value)}></IonInput>
                                                </div>
                                            </div>
                                            <div className="form-row">
                                                <div className="form-group col-md-6">
                                                    <label >Phone</label>
                                                    <IonInput type="text" className="form-control" placeholder="Enter phone Number.."
                                                        value={data?.maintenance_provider_phone}
                                                        onIonChange={(e) => onFormChange('maintenance_provider_phone', e.detail.value)}></IonInput>
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <label >Email</label>
                                                    <IonInput type="email" className="form-control" placeholder="Add Contact Email"
                                                        value={data?.maintenance_provider_email}
                                                        onIonChange={(e) => onFormChange('maintenance_provider_email', e.detail.value)}></IonInput>
                                                </div>
                                            </div>
                                            <div className="form-row">
                                                <div className="form-group custom-file1 mb-3 col-md-4 col-md-middle">
                                                    <label className="custom-file-label1" htmlFor="customFile">Documents</label>
                                                    <input multiple type="file" name="documents[]" onChange={e => onFileChange('documents', e)} />
                                                </div>
                                            </div>

                                            {/* <p className='ion-text-center sub-text'>Your provider will be notified of your request and will contact you shortly with pricing and schedule.</p> */}

                                            <button type="submit" className="btn w-100 Btnclr">Add</button>

                                            <IonAlert
                                                isOpen={showAlert}
                                                onDidDismiss={() => setShowAlert(false)}
                                                header="Add Job"
                                                subHeader=""
                                                message="Data Submitted Successfully !"
                                                buttons={['OK']}
                                            />

                                        </form>


                                    </IonCardContent>
                                </IonCard>
                            </IonCol>
                        </IonRow>

                    </IonGrid>
                    <IonToast
                        isOpen={isToastOpen}
                        message={toastMessage}
                        onDidDismiss={() => setIsToastOpen(false)}
                        duration={5000}
                    ></IonToast>
                </IonContent>

            </IonContent >
        </IonPage >

    );
};

export default AddJob;
