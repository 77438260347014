import { IonContent, IonPage, IonButton, useIonLoading } from '@ionic/react';
import { useHistory } from "react-router-dom";

import './index.css';

const ForgotPassword: React.FC = () => {
    const history = useHistory();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [present, dismiss] = useIonLoading();

    return (
        <>
            <IonPage>
                <IonContent className="ion-padding ">
                    <div>
                        <h2>Forgot Password</h2>
                        <p className="sub-text">No worries, we'll send you reset instructions.</p>
                    </div>
                    <div className="marg-top-50 input-icons">
                        <h5>Phone Number</h5>
                        <i className="fa fa-mobile icon" aria-hidden="true"></i>
                        <input type="number" placeholder="Enter Your Phone Number" className='input-text' />
                    </div>
                    <div className='marg-top-20'>
                        <IonButton expand="block" color="primary"
                            onClick={
                                (e) => {
                                    e.preventDefault();
                                    history.push('/resetCode');
                                }
                            }>
                            Reset Password
                        </IonButton>
                        <p className="sub-text ion-text-center"
                            onClick={
                                (e) => {
                                    e.preventDefault();
                                    history.push('/login');
                                }
                            } >
                            <i className="fa fa-arrow-left" aria-hidden="true"></i> Back to log In
                        </p>
                    </div>
                </IonContent>
            </IonPage>
        </>
    );
};

export default ForgotPassword;
