const implementedTypes = [
    {
        "name": "Yes",
        "code": "YES"
    },
    {
        "name": "No",
        "code": "NO"
    },
    {
        "name": "N/A",
        "code": "NA"
    }
]

export default implementedTypes;