import {
    IonAlert,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardSubtitle,
    IonCardTitle,
    IonCol,
    IonGrid,
    IonHeader,
    IonIcon,
    IonRow,
    IonText,
    IonToast,
    IonToolbar,
    useIonViewDidEnter
} from '@ionic/react';
import { logOutOutline } from "ionicons/icons";
import {
    IonContent,
    IonPage,
    IonButton,
    IonButtons
} from '@ionic/react';
import './index.css';
import { useHistory } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { API_URL } from '../../config';
import { authFetch, getSessionState } from '../../auth-provider';
import { logout } from '../../auth-provider';

const Home: React.FC = () => {
    const history = useHistory();
    const sessionState = getSessionState();

    // eslint-disable-next-line
    const [notifications, setNotifications] = useState([]);
    const [jobsCount, setJobsCount] = useState(0);
    const [providersCount, setProvidersCount] = useState(0);
    
    const [iserror, setIserror] = useState<boolean>(false);
    const [message, setMessage] = useState<string>("");
    const [isToastOpen, setIsToastOpen] = useState(false);
    // eslint-disable-next-line
    const [toastMessage, setToastMessage] = useState<string>("");

    console.log('sessionState', sessionState);
    const user = sessionState?.user;

    useIonViewDidEnter(() => {
        authFetch(API_URL + "/notifications", {
            method: 'GET',
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' }
        })
            .then(r => {
                if(r.ok === false && r.status === 401) {
                    logout();
                    history.push('/login');
                    return;
                }
                return r.json()
            })
            .then(response => {
                const message = response?.message;
                const data = response?.data;
                setJobsCount(response?.jobs);
                setProvidersCount(response?.providers);
                if(!data) {
                    setIserror(true);
                    setMessage(message);
                    console.log('response', response);
                }

                console.log('data', data);
                setNotifications(data);
            }).catch(err => {
                console.log(err)
            });
    });

    return (

        <IonPage className='dashboard-page'>
            <IonContent>

                <IonHeader className="home header-toolbar">
                    <IonToolbar>
                        <IonButtons className="ion-justify-content-center">
                            <IonButtons className="ion-justify-content-center">
                                <h1 className="m-0 ion-justify-content-center text-light head-class" color="light">DASHBOARD</h1>
                            </IonButtons>
                        </IonButtons>

                        <IonButtons slot="end">
                            <IonButton color="light" onClick={(e) => {
                                e.preventDefault();
                                logout();
                                history.push('/login');
                            }}>
                                <IonIcon icon={logOutOutline} />
                            </IonButton>
                        </IonButtons>
                    </IonToolbar>
                </IonHeader>

                <IonContent>
                    <div>
                        <IonAlert
                            isOpen={iserror}
                            onDidDismiss={() => setIserror(false)}
                            cssClass="my-custom-class"
                            header={"Error!"}
                            message={message}
                            buttons={["Dismiss"]}
                        />
                    </div>
                    <div className="topHeader"></div>
                    <IonGrid style={{ marginBottom: "55px" }}>
                        <IonRow className="ion-justify-content-center">
                            <IonCol size="12" className="ion-justify-content-center ion-align-items-center ion-text-center">
                                <IonCard className="profileHeader">

                                    <IonCardContent>

                                        <IonRow>
                                            {/* <IonCol size="4">
                                                <img src={avatar} alt="avatar" className="avatar" />
                                            </IonCol> */}

                                            <IonCol size="12">
                                                <IonRow className="profileInfo">
                                                    <IonCol size="12">
                                                        <IonText color="dark" className="profileName">
                                                            <p>{user?.name}</p>
                                                        </IonText>
                                                        <IonText color="medium">
                                                            <p>{user?.email}</p>
                                                        </IonText>
                                                    </IonCol>
                                                </IonRow>

                                                <IonRow className="profileStats">

                                                    <IonCol className="profileStat">
                                                        <IonCardTitle>{jobsCount}</IonCardTitle>
                                                        <IonCardSubtitle>Jobs</IonCardSubtitle>
                                                    </IonCol>

                                                    <IonCol className="profileStat">

                                                        <IonCardTitle>{providersCount}</IonCardTitle>
                                                        <IonCardSubtitle>Providers</IonCardSubtitle>
                                                    </IonCol>
                                                </IonRow>
                                            </IonCol>
                                        </IonRow>

                                        <IonRow>
                                            <IonCol size="12">
                                                <p>
                                                    <i className="fa fa-envelope icon" aria-hidden="true"></i>
                                                    New Messages
                                                </p>
                                            </IonCol>
                                        </IonRow>
                                    </IonCardContent>
                                </IonCard>
                            </IonCol>
                        </IonRow>

                        <IonRow className='profileStatusContainer' style={{ margin: "0px 0px 30px 0px" }}>
                            <IonCol size="12">
                                <IonCard className="profileCard">
                                    <IonCardHeader style={{paddingInline: "0px"}}>
                                        <IonRow className="profileStatus">
                                            <IonCardSubtitle>What do you want to do today?</IonCardSubtitle>
                                        </IonRow>
                                    </IonCardHeader>

                                </IonCard>
                            </IonCol>
                        </IonRow>

                        <IonRow className="profileActionContainer">
                            <IonCol size="12">
                                <IonCard className="">
                                    {/* <IonCardContent className="border menu-item">
                                        <IonRow className="ion-justify-content-between">
                                            <IonCardSubtitle onClick={(e) => {
                                                e.preventDefault();
                                                history.push('/viewjobs');
                                            }}>View Jobs</IonCardSubtitle>
                                        </IonRow>
                                    </IonCardContent> */}
                                    <IonCardContent className="border menu-item">
                                        <IonRow className="ion-justify-content-between">
                                            <IonCardSubtitle onClick={(e) => {
                                                e.preventDefault();
                                                history.push('/AddJob');
                                            }}>ADD JOB</IonCardSubtitle>
                                        </IonRow>
                                    </IonCardContent>
                                    <IonCardContent className="border menu-item">
                                        <IonRow className="ion-justify-content-between">
                                            <IonCardSubtitle onClick={(e) => {
                                                e.preventDefault();
                                                history.push('/perfromInspection');
                                            }}>PERFORM NEW INSPECTION</IonCardSubtitle>
                                        </IonRow>
                                    </IonCardContent>
                                    <IonCardContent className="border menu-item">
                                        <IonRow className="ion-justify-content-between">
                                            <IonCardSubtitle onClick={(e) => {
                                                e.preventDefault();
                                                history.push('/jobDocuments');
                                            }}>VIEW JOB DOCUMENTS</IonCardSubtitle>
                                        </IonRow>
                                    </IonCardContent>
                                    <IonCardContent className="border menu-item">
                                        <IonRow className="ion-justify-content-between">
                                            <IonCardSubtitle onClick={(e) => {
                                                e.preventDefault();
                                                history.push('/maintenanceRequest');
                                            }}>MAINTENANCE REQUEST FOR EXISTING PROJECT</IonCardSubtitle>
                                        </IonRow>
                                    </IonCardContent>
                                    <IonCardContent className="border menu-item">
                                        <IonRow className="ion-justify-content-between">
                                            <IonCardSubtitle onClick={(e) => {
                                                e.preventDefault();
                                                history.push('/serviceRequest');
                                            }}>SERVICE REQUEST FOR NEW PROJECT</IonCardSubtitle>
                                        </IonRow>
                                    </IonCardContent>
                                    <IonCardContent className="border menu-item">
                                        <IonRow className="ion-justify-content-between">
                                            <IonCardSubtitle onClick={(e) => {
                                                e.preventDefault();
                                                history.push('/contactServiceProvider');
                                            }}>CONTACT US</IonCardSubtitle>
                                        </IonRow>
                                    </IonCardContent>
                                </IonCard>
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                    <IonToast
                        isOpen={isToastOpen}
                        message={toastMessage}
                        onDidDismiss={() => setIsToastOpen(false)}
                        duration={5000}
                    ></IonToast>
                </IonContent>
            </IonContent >
        </IonPage >
    );
};

export default Home;
