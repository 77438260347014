const conditionTypes = [
    {
        "name": "Effective",
        "code": "EFFECTIVE"
    },
    {
        "name": "Ineffective",
        "code": "INEFFECTIVE"
    },
    {
        "name": "N/A",
        "code": "NA"
    }
]

export default conditionTypes;