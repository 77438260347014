const inspectionTypes = [
    {
        "name": "Initial EC Installation",
        "code": "INITIAL_EC_INSTALLATION"
    },
    {
        "name": "Scheduled Weekly",
        "code": "SCHEDULED_WEEKLY"
    },
    {
        "name": "1/2\' Rainfall Event",
        "code": "1_2_RAINFALL_EVENT"
    },
    {
        "name": "Final (NOT)",
        "code": "FINAL_NOT"
    },
    {
        "name": "Post Construction",
        "code": "POST_CONSTRUCTION"
    },
    {
        "name": "Other",
        "code": "OTHER"
    }
]

export default inspectionTypes;