import { IonContent, IonPage, IonButton, IonAlert, IonInput, IonToast, useIonLoading } from '@ionic/react';
import { useState } from 'react';
import { useHistory } from "react-router-dom";
import { API_URL } from '../../config';
import './index.css';

const Register: React.FC = () => {
    const history = useHistory();

    const [name, setName] = useState<string>();
    const [email, setEmail] = useState<string>();
    const [password, setPassword] = useState<string>();
    const [passwordConfirmation, setPasswordConfirmation] = useState<string>();
    const [iserror, setIserror] = useState<boolean>(false);
    const [message, setMessage] = useState<string>("");
    const [isToastOpen, setIsToastOpen] = useState(false);
    const [toastMessage, setToastMessage] = useState<string>("");
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [present, dismiss] = useIonLoading();

    const onSubmit = (e: any) => {
        e.preventDefault();

        setIserror(false);
        setMessage("");
        setIsToastOpen(false);
        setToastMessage("");

        present({
            message: 'Submitting...',
        });

        fetch(API_URL + "/register", {
            method: 'POST',
            body: JSON.stringify({
                "name": name,
                "email": email,
                "password": password,
                "password_confirmation": passwordConfirmation
            }),
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' }
        })
            .then(r => r.json())
            .then(session => {
                dismiss();
                const message = session.message;
                if(!message) {
                    setIsToastOpen(true);
                    setToastMessage("Registered successfully. Please login.");
                    history.push("/login");
                    return;
                }
                setIserror(true);
                setMessage(message);
                // console.log('session', session);
            }).catch(e => {
                console.error(e);
                dismiss();
                setIserror(true);
                setMessage(e?.message || 'Server Error');
            });
    };

    return (
        <>
            <IonPage>
                <IonContent className="ion-padding ">
                    <div>
                        <IonAlert
                            isOpen={iserror}
                            onDidDismiss={() => setIserror(false)}
                            cssClass="my-custom-class"
                            header={"Error!"}
                            message={message}
                            buttons={["Dismiss"]}
                        />
                    </div>
                    <div>
                        <h2>Register</h2>
                        <p className="sub-text">Create New Account</p>
                    </div>
                    <div className="marg-top-50 input-icons">
                        <h5>Name</h5>
                        <i className="fa fa-user-circle-o  icon" aria-hidden="true"></i>
                        <IonInput
                            type="text"
                            placeholder="Enter Name"
                            class='input-text'
                            value={name}
                            onIonChange={(e) => setName(e.detail.value!)}
                        ></IonInput>
                    </div>
                    {/* <div className="input-icons">
                        <h5>Last Name</h5>
                        <i className="fa fa-user-circle-o  icon" aria-hidden="true"></i>
                        <input type="text" placeholder="Enter Last Name" className='input-text' />
                    </div> */}
                    <div className="input-icons">
                        <h5>Email</h5>
                        <i className="fa fa-envelope icon" aria-hidden="true"></i>
                        <IonInput
                            type="email"
                            placeholder="Enter Email"
                            className='input-text'
                            value={email}
                            onIonChange={(e) => setEmail(e.detail.value!)}
                        ></IonInput>
                    </div>
                    <div className="input-icons">
                        <h5>Password</h5>
                        <i className="fa fa-lock icon" aria-hidden="true"></i>
                        <IonInput
                            type="password"
                            placeholder="Enter Password"
                            className='input-text'
                            value={password}
                            onIonChange={(e) => setPassword(e.detail.value!)}
                        ></IonInput>
                    </div>
                    <div className="input-icons">
                        <h5>Confirm Password</h5>
                        <i className="fa fa-lock icon" aria-hidden="true"></i>
                        <IonInput
                            type="password"
                            placeholder="Enter Password Confirmation"
                            className='input-text'
                            value={passwordConfirmation}
                            onIonChange={(e) => setPasswordConfirmation(e.detail.value!)}
                        ></IonInput>
                    </div>

                    <div className="marg-top-20">
                        <IonButton expand="block" color="primary" onClick={onSubmit}>Sign Up</IonButton>

                        <p className="sub-text ion-text-center" onClick={(e) => {
                            e.preventDefault();
                            history.push('/login');
                        }}>Already have an account? Sign In</p>
                    </div>
                    <IonToast
                        isOpen={isToastOpen}
                        message={toastMessage}
                        onDidDismiss={() => setIsToastOpen(false)}
                        duration={5000}
                    ></IonToast>
                </IonContent>
            </IonPage>
        </>
    );
};

export default Register;
