import { IonContent, IonPage, IonImg, IonButton } from '@ionic/react';
import Logo from '../../assets/images/SiltTracker-logo.webp';
import { useHistory } from "react-router-dom";
import './index.css';
import { useAuth } from '../../auth-provider';

const Home: React.FC = () => {
    const history = useHistory();
    const [logged] = useAuth();

    return (
        <IonPage>
            <IonContent className="ion-padding ">
                <div className="center-webkit">
                    <IonImg className="ion-margin width-webkit" src={Logo} alt="Silt Tracker" style={{ marginTop: "5em " }}></IonImg>
                </div>
                <h1 className="title ion-text-center">Silt Tracker</h1>
                <p className='ion-text-center sub-text'>Innovative SWPPP Inspections and Proactive Maintenance Tracker</p>
                <div className='button-parent-div'>
                    {
                    !logged && ( 
                        <IonButton className="marg-top-20" expand="block" color="primary" onClick={(e) => {
                            e.preventDefault();
                            history.push('/register');
                        }}>Create an Account</IonButton>
                    )}
                    {
                    !logged && ( 
                        <IonButton expand="block" color="light" onClick={(e) => {
                            e.preventDefault();
                            history.push('/login');
                        }}>Sign In</IonButton>
                    )}
                    {
                    logged && ( 
                        <IonButton className="marg-top-20" expand="block" color="primary" onClick={(e) => {
                            e.preventDefault();
                            history.push('/dashboard');
                        }}>Dashboard</IonButton>
                    )}
                </div>
            </IonContent>
        </IonPage>
    );
};

export default Home;
