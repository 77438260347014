import { IonContent, IonPage, IonButton, useIonLoading, } from '@ionic/react';
import { useHistory } from "react-router-dom";

import './index.css';

const ResetCode: React.FC = () => {
    const history = useHistory();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [present, dismiss] = useIonLoading();

    return (
        <>
            <IonPage>
                <IonContent className="ion-padding ">
                    <div>
                        <h2>Forgot Password</h2>
                    </div>
                    <div className="marg-top-50">
                        <h5>Enter code</h5>
                        <p className="sub-text">You have been sent a code to your number ***7830</p>
                        <input type="text" className='input-text' placeholder='e.g. G-809768' />
                    </div>
                    <div className='marg-top-20'>
                        <IonButton expand="block" color="primary"
                            onClick={
                                (e) => {
                                    e.preventDefault();
                                    history.push('/');
                                }
                            }>
                            Continue
                        </IonButton>
                        <p className="sub-text ion-text-center" onClick={(e) => {
                            e.preventDefault();
                            history.push('/forgotPassword');
                        }}>Didn't receive the code? Click to resend</p>
                        <p className="sub-text ion-text-center"
                            onClick={
                                (e) => {
                                    e.preventDefault();
                                    history.push('/login');
                                }
                            } >
                            <i className="fa fa-arrow-left" aria-hidden="true"></i> Back to log In
                        </p>
                    </div>
                </IonContent>
            </IonPage>
        </>
    );
};

export default ResetCode;
