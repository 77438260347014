import { IonContent, IonPage, IonButton, IonAlert, IonInput, IonToast, useIonLoading } from '@ionic/react';
import { useState } from 'react';
import { useHistory } from "react-router-dom";
import './index.css';
import { login } from '../../auth-provider';
import { API_URL } from '../../config';

const Login: React.FC = () => {
    const history = useHistory();
    const [email, setEmail] = useState<string>();
    const [password, setPassword] = useState<string>();
    const [iserror, setIserror] = useState<boolean>(false);
    const [message, setMessage] = useState<string>("");
    const [isToastOpen, setIsToastOpen] = useState(false);
    const [toastMessage, setToastMessage] = useState<string>("");
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [present, dismiss] = useIonLoading();

    const onSubmit = (e: any) => {
        e.preventDefault();

        setIserror(false);
        setMessage("");
        setIsToastOpen(false);
        setToastMessage("");

        present({
            message: 'Submitting...',
        });

        fetch(API_URL + "/login", {
            method: 'POST',
            body: JSON.stringify({
                "email": email,
                "password": password
            }),
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' }
        })
            .then(r => r.json())
            .then(session => {
                dismiss();
                const message = session.message;
                if(!message) {
                    setIsToastOpen(true);
                    setToastMessage("Logged in successfully.");
                    login({
                        accessToken: session.data.tokens.access_token,
                        refreshToken: session.data.tokens.refresh_token,
                        user: session.data.user,
                    })
                    history.push("/dashboard");
                    return;
                }
                setIserror(true);
                setMessage(message);
            }).catch(e => {
                console.error(e);
                dismiss();
                setIserror(true);
                setMessage(e?.message || 'Server Error');
            });
    };

    return (
        <>
            <IonPage>
                <IonContent className="ion-padding ">
                    <div>
                        <IonAlert
                            isOpen={iserror}
                            onDidDismiss={() => setIserror(false)}
                            cssClass="my-custom-class"
                            header={"Error!"}
                            message={message}
                            buttons={["Dismiss"]}
                        />
                    </div>
                    <div>
                        <h2>Sign in</h2>
                        <p className="sub-text">Log into your account</p>
                    </div>
                    <div className="marg-top-50 input-icons">
                        <h5>Email</h5>
                        <i className="fa fa-envelope icon" aria-hidden="true"></i>
                        {/* <input type="number" placeholder="Enter Phone Number" className='input-text' /> */}
                        <IonInput
                            type="email"
                            placeholder="Enter Email"
                            className='input-text'
                            value={email}
                            onIonChange={(e) => setEmail(e.detail.value!)}
                            >
                        </IonInput>
                    </div>
                    <div className="input-icons">
                        <h5>Password</h5>
                        <i className="fa fa-lock icon" aria-hidden="true"></i>
                        {/* <input type="password" placeholder="Enter Password" className='input-text' /> */}
                        <IonInput
                            type="password"
                            placeholder="Enter Password"
                            className='input-text'
                            value={password}
                            onIonChange={(e) => setPassword(e.detail.value!)}
                            >
                        </IonInput>
                    </div>

                    <div className='marg-top-20'>
                        <IonButton expand="block" color="primary" onClick={onSubmit}>Sign In</IonButton>
                        {/* <p className="sub-text ion-text-center" onClick={(e) => {
                            e.preventDefault();
                            history.push('/forgotPassword');
                        }}>Forgot Password?</p> */}
                        <p className="sub-text ion-text-center" onClick={(e) => {
                            e.preventDefault();
                            history.push('/register');
                        }}>Don't have an account? Sign up</p>
                    </div>
                    <IonToast
                        isOpen={isToastOpen}
                        message={toastMessage}
                        onDidDismiss={() => setIsToastOpen(false)}
                        duration={5000}
                    ></IonToast>
                </IonContent>
            </IonPage>
        </>
    );
};

export default Login;
