const inspectionItemTypes = [
    {
        "name": "BMP / ACTIVITY: SWPPP POSTING?",
        "code": "BMP_ACTIVITY_SWPPP_POSTING"
    },
    {
        "name": "BMP / ACTIVITY: SWPPP INFORMATION ONSITE?",
        "code": "BMP_ACTIVITY_SWPPP_INFORMATION_ONSITE"
    },
    {
        "name": "BMP / ACTIVITY: ARE WEEKLY INSPECTIONS PERFORMED?",
        "code": "BMP_ACTIVITY_ARE_WEEKLY_INSPECTIONS_PERFORMED"
    },
    {
        "name": "BMP / ACTIVITY: CONSTRUCTION ENTRANCE",
        "code": "BMP_ACTIVITY_CONSTRUCTION_ENTRANCE"
    },
    {
        "name": "BMP / ACTIVITY: PORTABLE SANITATION",
        "code": "BMP_ACTIVITY_PORTABLE_SANITATION"
    },
    {
        "name": "BMP / ACTIVITY: TRASH CONTAINMENT",
        "code": "BMP_ACTIVITY_TRASH_CONTAINMENT"
    },
    {
        "name": "BMP / ACTIVITY: CONCRETE WASHOUT FACILITIY AND SIGNAGE",
        "code": "BMP_ACTIVITY_CONCRETE_WASHOUT_FACILITIY_AND_SIGNAGE"
    },
    {
        "name": "BMP / ACTIVITY: GENERAL HOUSEKEEPING",
        "code": "BMP_ACTIVITY_GENERAL_HOUSEKEEPING"
    },
    {
        "name": "BMP / ACTIVITY: SILT FENCE",
        "code": "BMP_ACTIVITY_SILT_FENCE"
    },
    {
        "name": "BMP / ACTIVITY: OTHER PERIMETER BARRIERS",
        "code": "BMP_ACTIVITY_OTHER_PERIMETER_BARRIERS"
    },
    {
        "name": "BMP / ACTIVITY: STREET SWEEPING/TRACK OUT",
        "code": "BMP_ACTIVITY_STREET_SWEEPING_TRACK_OUT"
    },
    {
        "name": "BMP / ACTIVITY: INLET PROTECTION - ABOVE GRADE",
        "code": "BMP_ACTIVITY_INLET_PROTECTION_ABOVE_GRADE"
    },
    {
        "name": "BMP / ACTIVITY: INLET PROTECTION - BELOW GRADE",
        "code": "BMP_ACTIVITY_INLET_PROTECTION_BELOW_GRADE"
    },
    {
        "name": "BMP / ACTIVITY: CHECK DAMS",
        "code": "BMP_ACTIVITY_CHECK_DAMS"
    },
    {
        "name": "BMP / ACTIVITY: STRAW LOGS/COIR LOGS",
        "code": "BMP_ACTIVITY_STRAW_LOGS_COIR_LOGS"
    },
    {
        "name": "BMP / ACTIVITY: TEMPORARY SEEDING AND MULCHING",
        "code": "BMP_ACTIVITY_TEMPORARY_SEEDING_AND_MULCHING"
    },
    {
        "name": "BMP / ACTIVITY: PERMANENT SEEDING AND MULCHING",
        "code": "BMP_ACTIVITY_PERMANENT_SEEDING_AND_MULCHING"
    },
    {
        "name": "BMP / ACTIVITY: MULCHING",
        "code": "BMP_ACTIVITY_MULCHING"
    },
    {
        "name": "BMP / ACTIVITY: ECB\'s / TRM\'s",
        "code": "BMP_ACTIVITY_ECB_S_TRM_S"
    },
    {
        "name": "BMP / ACTIVITY: ROCK DONUTS",
        "code": "BMP_ACTIVITY_ROCK_DONUTS"
    },
    {
        "name": "BMP / ACTIVITY: WQU (WATER QUALITY UNITS)",
        "code": "BMP_ACTIVITY_WQU_WATER_QUALITY_UNITS"
    },
    {
        "name": "BMP / ACTIVITY: RIP RAP",
        "code": "BMP_ACTIVITY_RIP_RAP"
    },
    {
        "name": "BMP / ACTIVITY: DEWATERING ACTIVITIES",
        "code": "BMP_ACTIVITY_DEWATERING_ACTIVITIES"
    },
    {
        "name": "BMP / ACTIVITY: ARE AREAS WITH SIDE SLOPES NOT ACTIVE PROPERLY STABILIZED?",
        "code": "BMP_ACTIVITY_ARE_AREAS_WITH_SIDE_SLOPES_NOT_ACTIVE_PROPERLY_STABILIZED"
    },
    {
        "name": "BMP / ACTIVITY: ARE SENSITIVE NATURAL RESOURCE AREAS PROPERLY PROTECTED?",
        "code": "BMP_ACTIVITY_ARE_SENSITIVE_NATURAL_RESOURCE_AREAS_PROPERLY_PROTECTED"
    },
    {
        "name": "BMP / ACTIVITY: ARE DISCHARGE POINTS AND RECEIVING WATERS FREE OF SEDIMENT DEPOSITS?",
        "code": "BMP_ACTIVITY_ARE_DISCHARGE_POINTS_AND_RECEIVING_WATERS_FREE_OF_SEDIMENT_DEPOSITS"
    },
    {
        "name": "BMP / ACTIVITY: ARE CONCENTRATED FLOW AREAS NOT ACTIVELY BEING GRADED PROPERLY STABILIZED?",
        "code": "BMP_ACTIVITY_ARE_CONCENTRATED_FLOW_AREAS_NOT_ACTIVELY_BEING_GRADED_PROPERLY_STABILIZED"
    },
    {
        "name": "BMP / ACTIVITY: ARE OTHER MATERIALS THAT CONTAIN POTENTIAL STORMWATER CONTAMINATES PROPERLY STORED OR UNDER COVER?",
        "code": "BMP_ACTIVITY_ARE_OTHER_MATERIALS_THAT_CONTAIN_POTENTIAL_STORMWATER_CONTAMINATES_PROPERLY_STORED_OR_UNDER_COVER"
    },
    {
        "name": "BMP / ACTIVITY: ARE THE TEMPORARY SEDIMENT BASINS MAINTAINED AND FUNCTIONING AS DESIGNED?",
        "code": "BMP_ACTIVITY_ARE_THE_TEMPORARY_SEDIMENT_BASINS_MAINTAINED_AND_FUNCTIONING_AS_DESIGNED"
    },
    {
        "name": "BMP / ACTIVITY: OTHER 1 - DESCRIBE",
        "code": "BMP_ACTIVITY_OTHER1_DESCRIBE"
    },
    {
        "name": "BMP / ACTIVITY: OTHER 2 - DESCRIBE",
        "code": "BMP_ACTIVITY_OTHER2_DESCRIBE"
    },
    {
        "name": "BMP / ACTIVITY: OTHER 3 - DESCRIBE",
        "code": "BMP_ACTIVITY_OTHER3_DESCRIBE"
    }
]

export default inspectionItemTypes;