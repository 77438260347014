const options = [
    {
        "name": "Yes",
        "code": "YES"
    },
    {
        "name": "No",
        "code": "NO"
    }
]

export default options;