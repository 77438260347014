import { IonInput, IonSelect, IonSelectOption } from "@ionic/react";
import implementedTypes from "../../config/implemented-types";
import conditionTypes from "../../config/condition-types";
import maintenanceRequiredTypes from "../../config/maintenance-required-types";
import { useState } from "react";

function BmpActivity(props: any) {
    const [data, setData] = useState<any>({
        item_type: props.bmpId
    });

    const [fileData, setFileData] = useState<any>({});

    const onFormChange = (key:string, value: any) => {
        const updatedData = {
            ...data,
            ...{
                [key]: value
            }
        };
        setData(updatedData);
        props.onBmpChange(props.bmpId, updatedData);
    };

    const onFileChange = (key:string, e: any) => {
        // // Get a reference to the file that has just been added to the input
        // const photo = e.target.files[0];
        // // Create a form data object using the FormData API
        // let data = new FormData();
        // // Add the file that was just added to the form data
        // data.append('photo', photo, photo.name);
        // // POST data to server using Fetch API
        const updatedData = {
            ...data,
            ...{
                [key]: e.target.files[0]
            }
        };
        setData(updatedData);
        props.onBmpChange(props.bmpId, updatedData);
    };
    return (
        <>
            <div className="head">
                <h2 className="h2 my-3" style={{fontWeight:"600"}}>{props.bmpTitle}</h2>
            </div>
            <div className="form-row">
                <div className="form-group col-md-4">
                    <label >IMPLEMENTED?</label>
                    <IonSelect className="form-control"
                        value={data?.implemented}
                        onIonChange={(e) => onFormChange('implemented', e.detail.value)}>
                        <IonSelectOption key={null} value={null}>Choose...</IonSelectOption>
                        {implementedTypes && implementedTypes.map((implementedType: any) => {
                            return (
                                <IonSelectOption key={implementedType.id} value={implementedType.id}>{implementedType.name}</IonSelectOption>
                            )
                        })}
                    </IonSelect>
                </div>
                <div className="form-group col-md-4">
                    <label >CONDITION?</label>
                    <IonSelect className="form-control"
                        value={data?.condition}
                        onIonChange={(e) => onFormChange('condition', e.detail.value)}>
                        <IonSelectOption key={null} value={null}>Choose...</IonSelectOption>
                        {conditionTypes && conditionTypes.map((conditionType: any) => {
                            return (
                                <IonSelectOption key={conditionType.id} value={conditionType.id}>{conditionType.name}</IonSelectOption>
                            )
                        })}
                    </IonSelect>
                </div>
                <div className="form-group col-md-4">
                    <label >MAINTENANCE REQUIRED?</label>
                    <IonSelect className="form-control"
                        value={data?.maintenance_required}
                        onIonChange={(e) => onFormChange('maintenance_required', e.detail.value)}>
                        <IonSelectOption key={null} value={null}>Choose...</IonSelectOption>
                        {maintenanceRequiredTypes && maintenanceRequiredTypes.map((maintenanceRequiredType: any) => {
                            return (
                                <IonSelectOption key={maintenanceRequiredType.id} value={maintenanceRequiredType.id}>{maintenanceRequiredType.name}</IonSelectOption>
                            )
                        })}
                    </IonSelect>
                </div>
            </div>


            <div className="form-row">
                <div className="form-group col-md-4">
                    <label >CORRECTIVE ACTION NOTES</label>
                    <IonInput type="text" className="form-control" placeholder="Enter Action Notes"
                        value={data?.corrective_action_notes}
                        onIonChange={(e) => onFormChange('corrective_action_notes', e.detail.value)}></IonInput>
                </div>
                <div className="form-group col-md-4">
                    <label >OTHER NOTES</label>
                    <IonInput type="text" className="form-control" placeholder="Enter here.."
                        value={data?.other_notes}
                        onIonChange={(e) => onFormChange('other_notes', e.detail.value)}></IonInput>
                </div>
                <div className="form-group custom-file1 mb-3 col-md-4 col-md-middle">
                    <label className="custom-file-label1" htmlFor="customFile">Photo</label>
                    <input type="file" accept="image/png, image/gif, image/jpeg" name="photo" onChange={e => onFileChange('photo', e)} />
                </div>
            </div>

        </>

    );

};


export default BmpActivity;