const weatherTypes = [
    {
        "name": "Clear",
        "code": "CLEAR"
    },
    {
        "name": "Cloudy",
        "code": "CLOUDY"
    },
    {
        "name": "Sleet",
        "code": "SLEET"
    },
    {
        "name": "Snow",
        "code": "SNOW"
    },
    {
        "name": "Fog",
        "code": "FOG"
    },
    {
        "name": "Rain",
        "code": "RAIN"
    }
]

export default weatherTypes;