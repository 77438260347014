import {
    IonAlert,
    IonBreadcrumb,
    IonBreadcrumbs,
    IonCard,
    IonCardContent,
    IonCheckbox,
    IonCol,
    IonGrid,
    IonHeader,
    IonIcon,
    IonInput,
    IonLabel,
    IonRow,
    IonSelect,
    IonSelectOption,
    IonToast,
    IonToolbar,
    useIonLoading,
    useIonViewDidEnter
} from '@ionic/react';
import { arrowBack } from "ionicons/icons";
import {
    IonContent,
    IonPage,
    IonButton,
    IonButtons
} from '@ionic/react';
import './index.css';
import { Link, useHistory } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { API_URL, STORAGE_URL } from '../../config';
import { authFetch } from '../../auth-provider';
import states from '../../config/states';
import areaUnits from '../../config/area-units';
import { logout } from '../../auth-provider';
import * as utils from '../../services/utils';
import inspectionTypes from '../../config/inspection-types';
import options from '../../config/options';
import weatherTypes from '../../config/weather-types';
import inspectionItemTypes from '../../config/inspection-item-types';
import { constant } from 'lodash';

const JobDocuments: React.FC = () => {
    const history = useHistory();

    const [showAlert, setShowAlert] = useState(false);
    // eslint-disable-next-line
    const [jobs, setJobs] = useState([]);
    const [job, setJob] = useState(null);
    const [documents, setDocuments] = useState<any[]>([]);
    const [document, setDocument] = useState<any>(null);

    const [data, setData] = useState<any>();

    const [iserror, setIserror] = useState<boolean>(false);
    const [message, setMessage] = useState<string>("");
    const [isToastOpen, setIsToastOpen] = useState(false);
    const [toastMessage, setToastMessage] = useState<string>("");
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [present, dismiss] = useIonLoading();

    useIonViewDidEnter(() => {
        fetchJobs();
    })

    const onFormChange = (key:string, value: any) => {
        console.log('[onFormChange]');
        console.log('key', key);
        console.log('value', value);
        console.log('data', data);
        console.log('data', {
            ...data,
            ...{
                [key]: value
            }
        });

        setData({
            ...data,
            ...{
                [key]: value
            }
        })
    };

    const onProjectChange = (value: any) => {
        console.log('[onProjectChange]');
        console.log('key', 'project_id');
        console.log('value', value);
        console.log('data', data);
        console.log('data', {
            ...data,
            ...{
                'project_id': value,
                'document_id': null
            }
        });

        setData({
            ...data,
            ...{
                'project_id': value,
                'document_id': null
            }
        })
        if(value) {
            fetchJob(value);
        }
    };

    const onDocumentChange = (value: any) => {
        console.log('[onDocumentChange]');
        console.log('key', 'document_id');
        console.log('value', value);
        console.log('data', data);
        console.log('data', {
            ...data,
            ...{
                'document_id': value
            }
        });

        setData({
            ...data,
            ...{
                'document_id': value
            }
        })

        const currentDocument: any = documents.find((document: any) => document.download_link === value);
        setDocument(currentDocument);
    };
    const onSubmit = (e: any) => {
        e.preventDefault();

        setIserror(false);
        setMessage("");
        setIsToastOpen(false);
        setToastMessage("");

        present({
            message: 'Submitting...',
        });

        authFetch(API_URL + "/job-documents/send", {
            method: 'POST',
            body: JSON.stringify(data),
            headers: { 'Content-Type': 'application/json' }
        })
            .then(r => {
                if(r.ok === false && r.status === 401) {
                    logout();
                    history.push('/login');
                    return;
                }
                return r.text()
            })
            .then(response => {
                dismiss();
                if(response === '') {
                    setIsToastOpen(true);
                    setToastMessage("Job document sent successfully.");
                    history.push("/dashboard");
                    return;
                }
                // const message = response?.message;
                // const data = response?.data;
                // setIserror(true);
                // setMessage(message);
                // console.log('response', response);
            }).catch(err => {
                dismiss();
                console.log(err)
            });
    };

    const viewDocument = () => {
        console.log('document', document);
    };

    const fetchJobs = () => {

        const searchData = {
            type: 'inspection_belongsto_project_relationship',
            method: 'add'
        };

        authFetch(API_URL + "/inspections/relation?" + utils.objectToQueryString(searchData), {
            method: 'GET',
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' }
        })
            .then(r => {
                if(r.ok === false && r.status === 401) {
                    logout();
                    history.push('/login');
                    return;
                }
                return r.json()
            })
            .then(response => {
                const message = response?.message;
                const results = response?.results;
                if(!results) {
                    setIserror(true);
                    setMessage(message);
                    console.log('response', response);
                }

                console.log('results', results);
                setJobs(results);
            }).catch(err => {
                console.log(err)
            });
    }

    const mapDocuments = (job: any) => {
        const documents = JSON.parse(job.documents) || [];
        console.log(documents);
        return [
            ...documents,
            ...job?.inspections.map((inspection: any) => {
                return inspection?.inspectionItems.filter((item: any) => {
                    return item.photo;
                }).map((item: any) => {
                    return {
                        download_link: item.photo,
                        code: item.item_type,
                        original_name: inspectionItemTypes.find((inspectionItemType: any) => inspectionItemType.code === item.item_type)?.name
                    };
                });
            }).filter((inspection: any) => {
                return inspection.length;
            }).flat()
        ]
    }

    const fetchJob = (id: number) => {

        authFetch(API_URL + `/jobs/${id}?`, {
            method: 'GET',
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' }
        })
            .then(r => {
                if(r.ok === false && r.status === 401) {
                    logout();
                    history.push('/login');
                    return;
                }
                return r.json()
            })
            .then(response => {
                const message = response?.message;
                const data = response?.data;
                if(!data) {
                    setIserror(true);
                    setMessage(message);
                    console.log('response', response);
                }

                console.log('data', data);
                console.log('mapDocuments', mapDocuments(data));
                setJob(data);

                // const documents = JSON.parse(data.documents);

                const documents = mapDocuments(data);
                if(documents.length === 0) {
                    setIserror(true);
                    setMessage("No documents found in the project.");
                }
                setDocuments(documents);
            }).catch(err => {
                console.log(err)
            });
    }

    return (

        <>


            <IonPage >
                <IonContent>
                    <IonHeader className="home header-toolbar">
                        <IonToolbar>
                            <IonButtons slot="start">

                                <IonButton color="light" onClick={(e) => {
                                    e.preventDefault();
                                    history.push('/dashboard');
                                }}>
                                    <IonIcon icon={arrowBack} ></IonIcon>
                                </IonButton>

                            </IonButtons>

                            <IonButtons className="ion-justify-content-center">
                                <IonButtons className="ion-justify-content-center">
                                    <h1 className="m-0 mr-4 ion-justify-content-center text-light head-class" color="light">JOB DOCUMENTS</h1>
                                </IonButtons>
                            </IonButtons>
                        </IonToolbar>
                    </IonHeader>
                    <IonContent>
                        <div>
                            <IonAlert
                                isOpen={iserror}
                                onDidDismiss={() => setIserror(false)}
                                cssClass="my-custom-class"
                                header={"Error!"}
                                message={message}
                                buttons={["Dismiss"]}
                            />
                        </div>

                        <div className="topHeader"></div>

                        <IonGrid style={{ marginBottom: "90px", marginTop: "40px " }}>



                            <IonRow className="profileActionContainer">
                                <IonCol size="12" className="">
                                    <IonCard className="profileHeader">

                                        <IonBreadcrumbs style={{ marginTop: "10px " }}>
                                            <Link to="dashboard"><IonBreadcrumb color="medium">Dashboard</IonBreadcrumb></Link>
                                            <IonBreadcrumb color="primary" active={true}>Job Documents</IonBreadcrumb>
                                        </IonBreadcrumbs>
                                        <IonCardContent>
                                            <form onSubmit={onSubmit}>
                                                <div className="form-row">
                                                    <div className="form-group col-md-12">
                                                        <label >Choose Project</label>
                                                        <IonSelect className="form-control" placeholder="Select project"
                                                            value={data?.project_id}
                                                            onIonChange={(e) => onProjectChange(e.detail.value)}>
                                                            <IonSelectOption key={null} value={null}>Choose...</IonSelectOption>
                                                            {jobs && jobs.map((job: any) => {
                                                                return (
                                                                    <IonSelectOption key={job.id} value={job.id}>{job.text}</IonSelectOption>
                                                                )
                                                            })}
                                                        </IonSelect>
                                                    </div>
                                                </div>
                                                {
                                                    data?.project_id ? (
                                                        <div>
                                                            <div className="form-row">
                                                                <div className="form-group col-md-12">
                                                                    <label >Choose Document</label>
                                                                    <IonSelect className="form-control" placeholder="Select document"
                                                                        value={data?.document_id}
                                                                        onIonChange={(e) => onDocumentChange(e.detail.value)}>
                                                                        <IonSelectOption key={null} value={null}>Choose...</IonSelectOption>
                                                                        {documents && documents.map((document: any) => {
                                                                            return (
                                                                                <IonSelectOption key={document.download_link} value={document.download_link}>{document.original_name}</IonSelectOption>
                                                                            )
                                                                        })}
                                                                    </IonSelect>
                                                                </div>
                                                                {/* <textarea className="form-control"></textarea> */}
                                                            </div>

                                                            {
                                                                data?.document_id && document ? (
                                                                    <div>
                                                                        <div className="form-row my-2 d-flex justify-content-between">
                                                                            {/* <button type="button" className="btn btn-light icon" onClick={() => viewDocument()}>View <i className="fa fa-eye" aria-hidden="true"></i></button> */}
                                                                            <a target='_blank' className="btn btn-light icon" href={STORAGE_URL + "/" + document.download_link}>View <i className="fa fa-eye" aria-hidden="true"></i></a>
                                                                            {/* <button type="button" className="btn btn-light icon">Email <i className="fa fa-envelope-open-o" aria-hidden="true"></i></button> */}
                                                                        </div>
                                                                        <div className="form-group">
                                                                            <label >Email Recipient</label>
                                                                            <IonInput type="text" className="form-control" placeholder="Enter Recipient"
                                                                                    value={data?.recipient}
                                                                                    onIonChange={(e) => onFormChange('recipient', e.detail.value)}></IonInput>
                                                                        </div>
                                                                        <button type="submit" className="btn Btnclr w-100">Send</button>
                                                                    </div>
                                                                ) : ''
                                                            }
                                                        </div>
                                                    ) : ''
                                                }


                                                <IonAlert
                                                    isOpen={showAlert}
                                                    onDidDismiss={() => setShowAlert(false)}
                                                    header="Job Documents"
                                                    subHeader=""
                                                    message="Data Submitted Successfully !"
                                                    buttons={['OK']}
                                                />

                                            </form>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                        </IonGrid>
                        <IonToast
                            isOpen={isToastOpen}
                            message={toastMessage}
                            onDidDismiss={() => setIsToastOpen(false)}
                            duration={5000}
                        ></IonToast>
                    </IonContent>
                </IonContent >
            </IonPage>
        </>

    );
};

export default JobDocuments;
