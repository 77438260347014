import { useEffect, useState } from "react";
import { authFetch, logout } from "./auth-provider";
import { API_URL } from "./config";

function useFetchList(url: any, options?: any, searchParams?: any) {
    const [data, setData] = useState<any>(null);
    const [meta, setMeta] = useState<any>(null);
    const [message, setMessage] = useState<any>(null);
    const [loading, setLoading] = useState(false);
    const [loadingMore, setLoadingMore] = useState(false);
    const [error, setError] = useState(null);
    useEffect(() => {
        setLoading(true); // set loading to true
        authFetch(API_URL + url + '?' + new URLSearchParams(searchParams), options)
            .then(r => {
                if(r.ok === false && r.status === 401) {
                    logout();
                    return;
                }
                return r.json();
            })
            .then((response) => {
                console.log('response', response);
                if(!response?.data) {
                    throw new Error(response?.message);
                }
                setData(response?.data);
                setMeta(response?.meta);
                setMessage(response?.message);
            })
            .catch((err) => {
                console.log('err', err?.message);
                setError(err?.message);
            })
            .finally(() => {
                setLoading(false);
            });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [url]);

    // Function to call when button is clicked
    const refetch = (callback?: Function) => {
        setLoading(true); // set loading to true
        authFetch(API_URL + url + '?' + new URLSearchParams(searchParams), options)
            .then(r => {
                if(r.ok === false && r.status === 401) {
                    logout();
                    return;
                }
                return r.json()
            })
            .then((response) => {
                setData(response?.data);
                setMeta(response?.meta);
                setMessage(response?.message);
            })
            .catch((err) => {
                console.log('err', err);
                setError(err);
            })
            .finally(() => {
                setLoading(false);
                callback && callback();
            });
    };

    // Function to call when button is clicked
    const loadMore = (callback?: Function) => {
        // console.log('load more');
        const currentPage = meta?.current_page || 1;
        const lastPage = meta?.last_page || 1;

        if(currentPage >= lastPage) {
            return;
        }

        // console.log('loading more');

        searchParams = {
            ...searchParams,
            ...{
                page: currentPage + 1
            }
        };

        setLoadingMore(true); // set loading to true
        authFetch(API_URL + url + '?' + new URLSearchParams(searchParams), options)
            .then(r => {
                if(r.ok === false && r.status === 401) {
                    logout();
                    return;
                }
                return r.json()
            })
            .then((response) => {
                setData([
                    ...data,
                    ...response?.data
                ]);
                setMeta(response?.meta);
                setMessage(response?.message);
            })
            .catch((err) => {
                console.log('err', err);
                setError(err);
            })
            .finally(() => {
                setLoadingMore(false);
                callback && callback();
            });
    };

    return { data, meta, loading, loadingMore, error, message, refetch, loadMore };
}

export default useFetchList;

