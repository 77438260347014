import { IonInput, IonSelect, IonSelectOption } from "@ionic/react";
import { useState, memo } from "react";
import states from "../../config/states";

function Inspector(props: any) {
    console.log('props', props);
    const [data, setData] = useState<any>({
        item_type: props.inspectorId
    });

    const onFormChange = (key:string, value: any) => {
        const updatedData = {
            ...data,
            ...{
                [key]: value
            }
        };
        setData(updatedData);
        props.onInspectorChange(props.inspectorId, updatedData);
    };
    return (
        <>
            <div className="head">
                <h2 className="h2 my-3" style={{fontWeight:"600"}}>{props.inspectorTitle}</h2>
            </div>

            <div className="form-group">
                <label >Contact Name</label>
                <IonInput type="text" className="form-control" placeholder="Enter Contact Name"
                        value={data?.name}
                        onIonChange={(e) => onFormChange('name', e.detail.value)}></IonInput>
            </div>
            <div className="form-group">
                <label htmlFor="inputAddress">Address</label>
                <IonInput type="text" className="form-control" placeholder="e.g. 1234 Main St..."
                        value={data?.location}
                        onIonChange={(e) => onFormChange('location', e.detail.value)}></IonInput>
            </div>
            <div className="form-row">
                <div className="form-group col-md-6">
                    <label >City</label>
                    <IonInput type="text" className="form-control" placeholder="Enter City Name"
                        value={data?.city}
                        onIonChange={(e) => onFormChange('city', e.detail.value)}></IonInput>
                </div>
                <div className="form-group col-md-4">
                    <label >State</label>
                    <IonSelect className="form-control" placeholder="Select state"
                        value={data?.state}
                        onIonChange={(e) => onFormChange('state', e.detail.value)}>
                        <IonSelectOption key={null} value={null}>Choose...</IonSelectOption>
                        {states && states.map((state: any) => {
                            return (
                                <IonSelectOption key={state.code} value={state.code}>{state.name}</IonSelectOption>
                            )
                        })}
                    </IonSelect>
                </div>
                <div className="form-group col-md-2">
                    <label htmlFor="inputZip">Zip</label>
                    <IonInput type="text" className="form-control" placeholder="e.g. 5624637"
                        value={data?.zip}
                        onIonChange={(e) => onFormChange('zip', e.detail.value)}></IonInput>
                </div>
            </div>
            <div className="form-row">
                <div className="form-group col-md-6">
                    <label >Phone</label>
                    <IonInput type="text" className="form-control" placeholder="Enter phone Number.."
                        value={data?.phone}
                        onIonChange={(e) => onFormChange('phone', e.detail.value)}></IonInput>
                </div>
                <div className="form-group col-md-6">
                    <label >Email</label>
                    <IonInput type="email" className="form-control" placeholder="Enter Your Email.."
                        value={data?.email}
                        onIonChange={(e) => onFormChange('email', e.detail.value)}></IonInput>
                </div>
            </div>

        </>

    );

};


export default memo(Inspector);