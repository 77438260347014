const areaUnits = [
    {
        "name": "Acres",
        "code": "acres"
    },
    {
        "name": "Square Metres (m2)",
        "code": "square_metres"
    },
    {
        "name": "Square Feet (ft2)",
        "code": "square_feets"
    },
    {
        "name": "Square Yard (yd2)",
        "code": "square_yards"
    }
]

export default areaUnits;