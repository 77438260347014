import { createAuthProvider } from 'react-token-auth';
import { API_URL } from './config';

interface User {
    id: number;
    role_id: number;
    name: string;
    email: string;
    avatar: string;
    email_verified_at?: any;
    settings: any[];
    created_at: string;
    updated_at: string;
}

type Session = {
    accessToken: string;
    refreshToken: string;
    user: User
};

export const { useAuth, authFetch, login, logout, getSessionState } = createAuthProvider<Session>({
    getAccessToken: session => {
        // console.log('[getAccessToken] session', session);
        return session.accessToken;
    },
    storage: localStorage,
    onUpdateToken: token =>
        fetch(API_URL +  '/refreshToken', {
            method: 'POST',
            body: JSON.stringify({
                "refresh_token": token.refreshToken
            }),
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' }
        })
            .then(r => {
                if (r.ok === false && r.status === 401) {
                    logout();
                    return;
                }
                return r.json()
            })
            .then(session => {
                console.log('[onUpdateToken] session', session);
                const data = session.data;
                if(!data) {
                    throw Error('Please login again');
                }
                return {
                    accessToken: session.data.tokens.access_token,
                    refreshToken: session.data.tokens.refresh_token,
                    user: session.data.user,
                };
            }),
});